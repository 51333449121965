/*
 * SPDX-FileCopyrightText: 2023 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

@import "variables.scss";
@import "~@tol/tol-ui/src/scss/tol-styling.scss";

:root {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }

  &::-webkit-scrollbar-track {
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
  }
}

#formORCID {
  text-align: center;
}

.tos-logo-text {
  fill: var(--bs-emphasis-color);
}

.tos-email-input-label {
  margin-right: 30px;
}

.tos-save-bttn {
  float: right;
}

.tos-email-input {
  font-size: 14px;
}

.rs-picker-menu {
  position: absolute !important;
  background-color: var(--bs-body-bg);
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  width: 100%;
}

.rs-form-group .rs-input {
  width: 100%;
}

.save-profile-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 20px;
}

.profile-form-wrapper {
  padding: 10px;
  padding-bottom: 5px;
}

.tabs-wrapper {
  padding: 20px;
  padding-bottom: 0px;

  .profile-header {
    margin-bottom: 20px;
  }
}

.tabs-centering {
  width: auto;
  display: block;
}

div.loading-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px !important;
  width: 400px !important;
  margin: auto;

  div.loading-spinner {
    border: 4px solid var(--bs-primary);
    border-top: solid 4px #ffffff;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    animation: spin 2s linear infinite;
  }

  p {
    font-size: 18px;
    margin-top: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.tos {
  background: url("../assets/tos-bg.png");
  background-size: cover !important;
  background-position: center !important;

  .tos-logo {
    height: 300px;
    width: 450px;
    flex-shrink: 0;
  }

  .roadmap {
    width: 100vw;
    height: 35vh;
    margin-right: 5%;
  }

  .rs-timeline-item-dot::before {
    background-color: $primary;
  }

  .rs-timeline-item-tail {
    background-color: $secondary;
  }

  .rs-timeline-item-last .rs-timeline-item-dot::before {
    background-color: $primary;
  }

  .rs-timeline-item-time {
    margin-left: 0%;
    padding-left: 0%;
    width: 20px;
  }

  .card-img,
  .card-img-top {
    width: 126px;
    height: 126px;
  }

  .profile-form-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media only screen and (max-width: 1440px) {
    .tos-logo {
      height: 300px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .tos-logo {
      height: 250px;
    }
  }

  @media (min-width: 767px) {
    .tos-logo {
      display: flex !important;
      width: 45vw;
      height: 30vh;
      align-self: center;
      justify-content: center;
    }

    .roadmap {
      height: 30vh;
    }
  }

  @media only screen and (max-height: 920px) and (max-width: 800px) {
    .roadmap {
      margin-bottom: 10%;
    }
  }

  @media only screen and (max-height: 850px) and (max-width: 800px) {
    .roadmap {
      margin-bottom: 30%;
    }
  }

  @media only screen and (max-height: 800px) and (max-width: 800px) {
    .roadmap {
      margin-bottom: 50%;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .rs-nav-item.rs-nav-item-active {
    background-color: #212529 !important;
    color: #fff !important;
  }
  .rs-nav-item {
    color: darken(rgb(173, 181, 189), 15%);
  }
}

.mailing-list-warning {
  text-align: center;
  margin-top: 50px;
}
